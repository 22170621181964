import styled from '@emotion/styled';

const StyledForgotPasswordHeader = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
const ForgotPasswordHeader = ({children}) => {
  return <StyledForgotPasswordHeader>{children}</StyledForgotPasswordHeader>;
};

export {ForgotPasswordHeader};
