import styled from '@emotion/styled';

const StyledSignUpHeader = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const SignUpHeader = ({children, className, style}) => {
  return (
    <StyledSignUpHeader className={className} style={{...style}}>
      {children}
    </StyledSignUpHeader>
  );
};

export {SignUpHeader};
