import styled from '@emotion/styled';
import {Loading} from '../components/loading';
const StyledLoadingIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingIcon = ({isLoading}) => {
  return (
    <StyledLoadingIcon>{isLoading ? <Loading /> : null}</StyledLoadingIcon>
  );
};

export {LoadingIcon};
