import React, {useRef, useState} from 'react';
import styled from '@emotion/styled';

import {useAuth} from '../../contexts/AuthContext';

import {Form, Button} from 'react-bootstrap';

import {Layout} from '../../layouts/default';
import {Spacer} from '../../components/spacer';
import {Notify} from '../../components/notify';

import {HelpLinkContainer} from '../../components/link/HelpLinkContainer';
import {HelpLink} from '../../components/link/HelpLink';
import {AMCInputHeader} from '../../components/amc-input/AMCInputHeader';
import {AMCInputPromotionMessage} from '../../components/amc-input/AMCInputPromotionMessage';

import {LoadingIcon} from '../../icons/LoadingIcon';

import {sanitize} from 'dompurify';
import {MD5} from 'crypto-js';
import {getRandomValue} from '../../plugins/random';
import {
  getSupaColoRedirectURL,
  getCheckAMCNumberEndpointURL,
} from '../../plugins/url';

const StyledAMCInput = styled.div`
  width: 100%;
`;

const AMCInput = ({children}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;

  const {currentUser} = useAuth();
  const amcNumberRef = useRef();
  const amcNumberConfirmRef = useRef();
  const [amcNumber, setAMCNumber] = useState('');
  const [amcNumberConfirm, setAMCNumberConfirm] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  if (!currentUser) {
    window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
    return;
  }

  const registerAMCNumber = ({amcNumber, firebaseIdToken}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `${getCheckAMCNumberEndpointURL()}/register/amc`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({amcNumber, token: firebaseIdToken}),
          }
        );
        resolve({
          httpStatusCode: response.status,
          message: await response.text(),
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setErrorMessage('');
      setLoading(true);

      const resultRegisterAMCNumber = await registerAMCNumber({
        amcNumber: Number(amcNumberRef.current.value),
        firebaseIdToken: await currentUser.getIdToken(),
      });

      if (resultRegisterAMCNumber.httpStatusCode === 200) {
        setLoading(false);
        setSuccessMessage('');
        const redirectURL = `${getSupaColoRedirectURL()}?media_user_id=${getRandomValue()}${Number(
          sanitize(amcNumberRef.current.value)
        )}${getRandomValue()}&email=${getRandomValue()}${MD5(
          currentUser.email
        )}${getRandomValue()}`;
        setTimeout(() => {
          if (redirectURL.match(/^https?:\/\//)) {
            window.location.href = redirectURL;
          }
        }, 1200);
      } else {
        setErrorMessage(
          'AMC番号に誤りがあるか、このAMC番号はすでに登録済みです'
        );
        setLoading(false);
        setDisabled(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const isSafeAMCNumberPattern = ({amcNumber, amcNumberConfirm}) => {
    if (amcNumber.length !== 10) {
      return false;
    }

    if (amcNumberConfirm.length !== 10) {
      return false;
    }

    const regex = new RegExp(/^\d{10}$/);

    const isMatchAMCNumber = regex.test(amcNumber);
    if (!isMatchAMCNumber) {
      return false;
    }

    const isMatchAMCNumberConfirm = regex.test(amcNumberConfirm);
    if (!isMatchAMCNumberConfirm) {
      return false;
    }

    if (amcNumber !== amcNumberConfirm) {
      return false;
    }

    return true;
  };

  const handleChangeAMCNumber = (e) => {
    const amcNumber = amcNumberRef.current.value;
    const amcNumberConfirm = amcNumberConfirmRef.current.value;
    setAMCNumber(amcNumber);
    const regex = new RegExp(/^\d{10}$/);
    const isMatchAMCNumber = regex.test(amcNumber);
    if (!isMatchAMCNumber) {
      setErrorMessage('半角数字10桁で入力してください');
      setDisabled(true);
      return;
    }
    const isMatchAMCNumberConfirm = regex.test(amcNumberConfirm);
    if (!isMatchAMCNumberConfirm) {
      setErrorMessage('半角数字10桁で入力してください');
      setDisabled(true);
      return;
    }
    if (amcNumber !== amcNumberConfirm) {
      setErrorMessage('AMC番号とAMC番号（確認用）が異なっています');
      setDisabled(true);
      return;
    } else {
      setDisabled(false);
    }
    setErrorMessage('');
  };

  const handleChangeConfirmAMCNumber = (e) => {
    const amcNumber = amcNumberRef.current.value;
    const amcNumberConfirm = amcNumberConfirmRef.current.value;
    setAMCNumberConfirm(amcNumberConfirm);
    const regex = new RegExp(/^\d{10}$/);
    const isMatchAMCNumber = regex.test(amcNumber);
    if (!isMatchAMCNumber) {
      setErrorMessage('半角数字10桁で入力してください');
      setDisabled(true);
      return;
    }
    const isMatchAMCNumberConfirm = regex.test(amcNumberConfirm);
    if (!isMatchAMCNumberConfirm) {
      setErrorMessage('半角数字10桁で入力してください');
      setDisabled(true);
      return;
    }
    if (amcNumber !== amcNumberConfirm) {
      setErrorMessage('AMC番号とAMC番号（確認用）が異なっています');
      setDisabled(true);
      return;
    } else {
      setDisabled(false);
    }
    setErrorMessage('');
  };

  const handleBlur = (e) => {
    const amcNumber = amcNumberRef.current.value;
    const amcNumberConfirm = amcNumberConfirmRef.current.value;
    if (isSafeAMCNumberPattern({amcNumber, amcNumberConfirm})) {
      setDisabled(false);
      setErrorMessage('');
    } else {
      setDisabled(true);
    }
  };

  return (
    <Layout>
      <StyledAMCInput>
        <Spacer />
        <AMCInputHeader>
          <div style={{fontSize: `26px`}}>{'ANAマイレージクラブ（AMC）'}</div>
          <div style={{fontSize: `26px`}}>{'お客様番号の確認'}</div>
        </AMCInputHeader>
        <AMCInputPromotionMessage>
          {'ANAマイレージクラブに登録しているご自身のAMC番号を設定してください'}
        </AMCInputPromotionMessage>
        <HelpLinkContainer>
          <HelpLink
            href="https://www.ana.co.jp/ja/jp/siteinfo/share/login-help/customer-number.html"
            target="_blank"
            rel="noreferrer"
          >
            {'※ご自身のAMC番号の調べ方はこちら'}
          </HelpLink>
        </HelpLinkContainer>
        <Spacer />
        <Form onSubmit={handleSubmit}>
          <Form.Group id="AMCnumber">
            <Form.Control
              type="AMCnumber"
              ref={amcNumberRef}
              value={amcNumber}
              placeholder="AMC番号  半角数字10桁"
              onChange={(e) => {
                handleChangeAMCNumber(e);
              }}
              onBlur={handleBlur}
              maxLength="10"
            />
          </Form.Group>
          <Spacer />
          <Form.Group id="AMCnumber-confirm">
            <Form.Control
              type="AMCnumber"
              ref={amcNumberConfirmRef}
              value={amcNumberConfirm}
              placeholder="AMC番号（確認用）"
              onChange={(e) => {
                handleChangeConfirmAMCNumber(e);
              }}
              onBlur={handleBlur}
              maxLength="10"
            />
          </Form.Group>
          <Spacer />
          <Notify errorMessage={errorMessage} successMessage={successMessage} />
          <Spacer />
          <Button
            disabled={disabled}
            className="w-100"
            type="submit"
            style={{
              position: 'relative',
            }}
          >
            {'次へ進む'}
            <LoadingIcon isLoading={loading} />
          </Button>
        </Form>
        <HelpLinkContainer>
          <HelpLink
            href="https://enq.supcolo.jp/enquete/spclbdd3095299"
            target="_blank"
            rel="noreferrer"
          >
            {'AMC番号を登録できない場合はこちら'}
          </HelpLink>
        </HelpLinkContainer>
      </StyledAMCInput>
    </Layout>
  );
};

export {AMCInput};
