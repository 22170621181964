import styled from '@emotion/styled';

const StyledAMCInputPromotionMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AMCInputPromotionMessage = ({children}) => {
  return (
    <StyledAMCInputPromotionMessage>{children}</StyledAMCInputPromotionMessage>
  );
};

export {AMCInputPromotionMessage};
