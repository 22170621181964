import styled from '@emotion/styled';

const StyledTermsOfServiceContent = styled.div`
  padding: 10px 10px;
`;

const TermsOfServiceContent = ({children}) => {
  return (
    <StyledTermsOfServiceContent>
      <p>アンケートサイトに関する利用規約</p>
      <br />
      <br />
      <p>
        この「アンケートサイトに関する利用規約」（以下「本規約」といいます。）は、ANA
        X株式会社（以下「甲」といいます。）が提供するアンケートサービス（以下「本サービス」といいます。）の利用条件等を定めるものです。本サイトのご利用にあたっては、本規約にご承諾の上、ご利用ください。
      </p>
      <br />
      <p>第1条（定義）</p>
      <p>本規約において、次に掲げる用語は、当該各号に定める意味を有します。</p>
      <p>
        （1）「モニター」とは、本規約に同意し当社所定の登録手続きを完了し、甲またはアンケート調査依頼事業者が提供するアンケートに参加を許諾した個人の方をいいます。
      </p>
      <p>
        （2）「モニター資格」とは、モニターとなりアンケ—トに回答する資格をいいます。
      </p>
      <p>
        （3）「アンケート調査依頼事業者」とは、本サービスにおいてアンケート調査の実施を希望する甲以外の事業者をいいます。
      </p>
      <p>
        （4）「アンケート依頼主体」とは、甲またはアンケート調査依頼事業者をいいます。
      </p>
      <p>
        （5）「本アンケート」とは、アンケート依頼主体が本サービスにおいてモニターに対して配信するアンケートをいいます。
      </p>
      <p>
        （6）「アンケート獲得ポイント」とは、モニターが本アンケートの一部に回答した結果、取得するポイントをいいます。
      </p>
      <p>
        （7）「回答情報」とは、本サービスにおいて、モニターが本アンケートに回答した回答内容情報をいい、以下の情報が含まれます。
      </p>
      <p>　　　（未既婚、子供の有無、居住形態、職業、年収、趣味、関心事等）</p>
      <p>
        （8）「本サイト」とは、甲または甲以外の第三者がWEB上で提供するアンケートサイトをいいます。
      </p>
      <br />
      <p>第2条（本サイトの内容）</p>
      <p>
        1.本サイトは、アンケート依頼主体のために調査を行う目的で、本アンケートを、モニターに対して配信し、モニターが当該本アンケートに回答できるサイトです。
      </p>
      <p>
        2.本サイトは、本アンケートの内容に応じて、特定のモニターに対して本アンケートの配信を行うものであり、全てのモニターに対して本アンケートの配信を確約するものではありません。
      </p>
      <p>
        3.本サイトの中には、モニターが、甲以外の第三者が運営するウェブサイト等において、本アンケートにご参加いただくものがあります。この場合のアンケート依頼主体は当該第三者であり、甲は当該アンケートに関するモニターの回答情報を取得いたしません。
      </p>
      <p>
        4.前項のアンケートに関する回答情報等の授受や管理等に関してトラブルが生じた場合には、モニターは当該第三者との間で直接解決するものとし、甲は何ら責任を負わないものとします。
      </p>
      <br />
      <p>第3条（モニター資格）</p>
      <p>
        1.甲は、次の各号のいずれかに該当するか又は該当するおそれがあると甲が判断する場合には、当該モニターについての本サイトのアンケートモニターとしての資格を取消すことができるものとします。
      </p>
      <p>
        （1）モニターが甲に提供した情報に、虚偽・誤記等、不正確な情報がある場合
      </p>
      <p>（2）他人（家族を含みます。）の情報の登録がなされた場合</p>
      <p>（3）架空の人物にて甲に情報の登録がなされた場合</p>
      <p>
        （4）本規約又は本サイトの利用に関し、甲が本規約等で提示する注意事項等に反する行為又は反するおそれのある行為がなされた場合
      </p>
      <p>（5）本サイトを不正利用し、又は不正利用のおそれがある場合</p>
      <p>
        （6）反社会的勢力に該当することが判明した場合又は該当するおそれが判明した場合
      </p>
      <p>（7）その他、甲がモニターとして不適切と判断した場合</p>
      <p>
        2.モニターは、メールアドレス、居住エリアその他の甲に登録されている情報について変更が生じた場合には、速やかに本サイトにて変更手続きを行うものとします。変更がなされなかったことにより生じた損害について、甲は一切責任を負いません。
      </p>
      <p>
        3.甲は、定期的にモニターの甲に登録されている情報の更新を行うことがあり、その結果アンケート獲得ポイントが消滅される可能性があります。
      </p>
      <br />
      <p>第4条（個人情報を含めた登録情報および回答情報の取扱い）</p>
      <p>
        1.甲は、本サイトをご利用頂くに際し、モニターから、以下の情報を適正かつ公正な手段により取得します。
      </p>
      <p>
        会員ID、メールアドレス、生年月日、性別、居住エリア（これらの情報をまとめて以下「登録情報」といいます。）
      </p>
      <p>
        2.甲は、登録情報および回答情報を、以下の目的のために利用いたします。
      </p>
      <p>
        （1）甲および甲が所属する企業グループのマーケティング分析および商品開発のため
      </p>
      <p>
        （2）甲および甲が適切と判断した他の企業の様々な商品情報やサービス情報、その他の営業案内および情報提供のため
      </p>
      <p>（3）その他、前各号に付随する目的のため</p>
      <p>
        3.前項に加え、甲は、登録情報および回答情報を含めたモニターが甲に提供する個人情報を、甲が定める最新のプライバシーポリシーに準拠して取扱います。
      </p>
      <p>
        4.甲は登録情報（なお、メールアドレスについては暗号化により特定の個人を識別できない情報に変換した上での提供となります。）および回答情報を、個人が特定されない形式に加工したうえで、甲に対してアンケート環境およびシステムを提供する株式会社スパコロおよび株式会社モニタスと共有いたします。株式会社スパコロおよび株式会社モニタスは、共有された情報を、アンケートの提供、統計レポートの作成等の目的で利用いたします。
      </p>
      <p>
        5.甲以外の第三者が運営するウェブサイト等において本アンケートにご参加いただく場合、甲は当該アンケートに関するモニターの回答情報を取得いたしません。アンケート依頼主体である当該第三者の提示するプライバシーポリシー等をご確認ください。
      </p>
      <p>
        6.甲は、本サイトの運営業務の一部を第三者に委託する場合には、当該委託する業務の遂行に必要な範囲で、モニターの個人情報を委託先に提供します。この場合、甲の定める基準に基づき個人情報を適切に取り扱っていると認めた委託先を選定した上、適正な取扱いを確保するための契約を締結し、委託先において個人情報の安全管理が図られるよう必要かつ適切な監督を行います。
      </p>
      <br />
      <p>第5条（アンケート獲得ポイントの付与）</p>
      <p>
        1.甲は、モニターの承諾を得て無償で本アンケートを実施する場合を除き、本アンケートに回答いただいた場合、モニターに対し本アンケートごとに設定されたアンケート獲得ポイントを付与します。
      </p>
      <p>
        2.甲は、モニターへの事前予告なく、アンケート獲得ポイント付与の有無や付与ポイント数を変更できるものとします。また、モニターからのアンケート獲得ポイントに関しての個別の問い合わせには回答できません。
      </p>
      <p>
        3.本サービス内で付与されるアンケート獲得ポイントの有効期限はモニターが最後にアンケートに回答完了した日より1年後の同月末日までとします。アンケート獲得ポイントの有効期限内に新たにアンケートに回答完了しなかった場合、保有する全アンケート獲得ポイントは失効します。
      </p>
      <p>
        4.本アンケートの回答として、公序良俗に反する記入や、明らかに不誠実・不適切と思われる記入がなされていると甲が判断した場合、当該本アンケートに対するアンケート獲得ポイントを付与せず、またはすでに付与したアンケート獲得ポイントを有効期限にかかわらず取り消すことがあります。
      </p>
      <p>
        5.モニターは、付与されたアンケート獲得ポイントを、第三者に対して譲渡・売買してはならず、また本アンケートに回答する際に利用した会員IDと別の会員IDに紐付けることはできないものとします。
      </p>
      <p>
        6.付与されるはずのアンケート獲得ポイントが付与されなかった場合または付与されたアンケート獲得ポイント数が誤っていた場合であって、甲が当該事実を確認することができたときにのみ、その是正を行います。
      </p>
      <p>
        7.モニターは甲が定める所定の単位および方法により、アンケート獲得ポイントをANAマイレージクラブのマイルに交換することができるものとします。
      </p>
      <p>
        8.その他アンケート獲得ポイントの付与・利用に関する最終的な判断は甲が行うものとし、モニターはこれに従うものとします。
      </p>
      <br />
      <p>第6条（守秘義務等）</p>
      <p>
        1.モニターは個別のアンケートで許可されているものを除き、本アンケートに対して回答を行ったか否かにかかわらず、本サイトによって知り得た一切の情報およびデータ（本アンケートの内容、本アンケートに含まれる文章、画像、動画等の一切を含みます。）を、第三者に開示又は漏えいしてはならず、また、本アンケートの回答以外のいかなる目的にも使用してはならないものとします。
      </p>
      <p>
        2.前項に定めたモニターの義務は、第12条所定のモニター登録解除手続完了後、第13条所定のモニター登録の抹消・失効後又は第17条所定の本サイトの終了後においても消滅せず、継続するものとします。
      </p>
      <br />
      <p>第7条（禁止事項）</p>
      <p>
        モニターは、本サイトの利用にあたり、以下の各号のいずれかに該当する行為および該当するおそれのある行為を行ってはならないものとします。
      </p>
      <p>(1)法令又は公序良俗に反する行為</p>
      <p>
        (2)甲、他のモニターその他第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、肖像権、法令上又は契約上の権利、その他一切の権利を侵害する行為
      </p>
      <p>(3)甲、他のモニターその他第三者を誹謗、中傷する行為</p>
      <p>(4)甲、他のモニターその他第三者に不利益を与える行為</p>
      <p>(5)本サービスの運営を妨害する行為</p>
      <p>(6)虚偽又は事実に反する情報を登録する行為</p>
      <p>(7)虚偽の情報又は事実に反する情報に基づき本アンケートに回答する行為</p>
      <p>
        (8)同一人物により重複してモニター登録を行う行為又はなりすまし登録を行う行為
      </p>
      <p>(9)不正回答を行う行為</p>
      <p>(10)第6条に定める守秘義務に違反する行為</p>
      <p>(11)反社会的勢力と何らかの関与を持つ行為</p>
      <p>(12)本規約の条項に反する行為</p>
      <p>
        (13)モニターの本サイトにおける全ての権利の第三者譲渡、貸与、相続その他処分を行う行為
      </p>
      <p>
        (14)甲、他のモニター、またはその他第三者のサーバまたはネットワークの機能を破壊したり、妨害したりする行為
      </p>
      <p>(15)本サービスによって得られた情報を商業的に利用する行為</p>
      <p>(16)その他、甲が不適当と判断する行為</p>
      <br />
      <p>　　　第８条（権利の帰属）</p>
      <p>
        1.本サイト、本サービスに関する著作権（著作権法第27条および同第28条の権利を含みます。）その他一切の権利は、アンケート依頼主体その他第三者に帰属するものであって、甲は、モニターに対し、かかる権利についていかなる権利を付与・許諾するものではありません。
      </p>
      <p>
        2.モニターは、第3条により登録されたモニターの登録情報に係る権利および回答情報に係る著作権（著作権法第27条および同第28条の権利を含みます。）その他一切の権利をアンケート依頼主体に譲渡するものとし、アンケート依頼主体は、登録情報および回答情報を自由に選択、修正および編集できることができるものとします。なお、モニターは、当該著作権に係る著作者人格権を甲および第三者に対して行使しないものとします。
      </p>
      <p>
        3.モニターが本条の規定に違反して問題が発生した場合、モニターは、自己の費用と責任において当該問題を解決するとともに、アンケート依頼主体その他第三者に何らの不利益、負担または損害を与えないよう適切な措置を講じなければなりません。
      </p>
      <br />
      <p>第９条（免責）</p>
      <p>
        1.甲は、本サービスに関連して、モニターに生じる一切の損害、不利益等について、甲の故意又は重過失に基づく場合を除き、何ら責任を負わないものとします。
      </p>
      <p>
        2.甲は、明示・黙示を問わず、本サービスの有用性、完全性、正確性等についていかなる保証を行うものではありません。
      </p>
      <br />
      <p>第10条（損害賠償）</p>
      <p>
        1.モニターが、本規約に違反または本サービスの利用に関連して甲又は第三者に損害を与えた場合、甲はモニターに付与したアンケート獲得ポイント、その他の特典に関する権利を無効にするとともに、当該モニターに対して一切の損害（逸失利益、弁護士報酬および訴訟費用等を含みますが、これらに限られません。）を賠償請求することができるものとします。
      </p>
      <p>
        2.甲は、甲（甲の代表者および使用者を含み、本項において以下も同様とする）の過失（重過失を除く）による債務不履行または不法行為によりモニターに生じた損害のうち間接損害、派生的損害、特別な事情から生じた損害（甲またはモニターが損害発生につき予見し、または予見し得た場合を含む）について一切の責任を負いません。
      </p>
      <p>
        3.甲は、本サービスに関して、モニターと他のモニターまたは第三者との間において生じた取引、または連絡に係る紛争について一切責任を負いません。
      </p>
      <br />
      <p>第11条（モニター登録解除）</p>
      <p>
        モニターは、モニター登録の解除を望む場合、本サイト所定の手続きに従い、登録解除を行うものとします。
      </p>
      <p>　　　</p>
      <p>　　　第12条（本アンケートの配信停止およびモニター登録抹消） </p>
      <p>
        モニターが、第7条に定める禁止事項に該当する行為を行ったと甲が判断した場合には、甲は、モニターの事前の承諾なく、本アンケートの配信停止、またはモニター登録を解除することができるものとします。
      </p>
      <br />
      <p>第13条（モニター登録解除後等における登録情報の取扱い）</p>
      <p>
        第11条、第12条に基づきモニター登録が解除、抹消若しくは失効した場合又は本サイトを終了した場合であっても、甲は、当該モニターからの各種問い合わせの照会に利用することを目的として、当該モニターに係る登録情報及び回答情報を一定期間保有します。
      </p>
      <br />
      <p>第14条（広告）</p>
      <p>
        1.甲は、本サイトにおいて、甲又は第三者のサービス等に係る広告を掲載できるものとします。
      </p>
      <p>
        2.本サイトから、本サイトに関するウェブサイト以外のウェブサイトへのリンクまたは第三者によるウェブサイトへのリンクを提供している場合、甲はモニターによるリンク先のウェブサイトの利用について、一切責任を負いません。また、本サイトに関するウェブサイトで利用可能な、甲の提供によらないコンテンツ、広告、商品、役務などの利用に起因または関連して生じた一切の損害について、甲は一切の責任を負いません。
      </p>
      <br />
      <p>第15条（反社会的勢力の排除）</p>
      <p>
        1.モニターは、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下、「暴力団員等」という）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを保証します。
      </p>
      <p>
        （1）自己若しくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること
      </p>
      <p>
        （2）暴力団員等に対して資金等を提供し、または便宜を供与する等、暴力団員等の維持、運営もしくは経営に協力もしくは関与をしていると認められる関係を有すること
      </p>
      <p>
        1.甲は、自らまたは第三者を利用して次の各号のいずれにも該当する行為を行わないことを確約します。
      </p>
      <p>(1)暴力的な要求行為</p>
      <p>(2)法的な責任を超えた不当な要求行為</p>
      <p>(3)取引に関して、脅迫的な言動をし、または暴力を用いる行為</p>
      <p>
        (4)風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
      </p>
      <p>(5)その他前各号に準ずる行為</p>
      <p>
        2.甲は、モニターが、暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、または第1項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場合には、モニターに対して何らの催告をすることなくモニター登録を解除することができます。
      </p>
      <p>
        3.甲は、前項によりモニター登録を解除した場合には、モニターに損害が生じたとしてもこれを一切賠償する責任はないことを確認し、モニターはこれを了承します。
      </p>
      <br />
      <p>第16条（本規約の変更）</p>
      <p>
        1.甲は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、適切な時期および適切な方法（本サイト等に掲示する方法、その他合理的な方法）により、事前に情報提供するものとします。
      </p>
      <p>
        2.甲は、モニターの一般の利益に適合する場合、または、変更に係る事情に照らして当該変更が合理的な範囲において、本規約を変更することができるものとします。
      </p>
      <p>
        3.前項にかかわらず、甲は、モニターの同意を得ることにより本規約の変更をすることができます。
      </p>
      <p>
        4.モニターが変更後の本規約に同意できない場合、直ちに第11条に従い、モニター登録解除の手続きを行ってください。本契約の変更後も、モニター登録を継続されている場合、甲は、モニターが変更後の本規約に同意したものとみなします。
      </p>
      <br />
      <p>第17条（本サイトの変更、停止等）</p>
      <p>
        1.甲は、モニターの一般の利益に適合する場合、または、変更に係る事情に照らして当該変更が合理的な範囲において、甲の判断により、モニターに対して事前に通知することなく、本サイトの全部又は一部を変更または追加できるものとします。ただし、当該変更または追加によって、変更または追加前の本サービスのすべての機能・性能が維持されることを保証するものではありません。
      </p>
      <p>
        2.甲は、以下のいずれかに該当する場合には、本サービスの利用の全部または一部を停止または中断することができます。この場合において、甲はモニターに対して、できる限り事前に通知するよう努めます。やむを得ない事由により事前の通知ができない場合には、事後速やかに通知するものとします。
      </p>
      <p>
        （1）本サービスに係るコンピューター・システムの点検または保守作業を定期的または緊急に行う場合
      </p>
      <p>（2）コンピューター、通信回線等が事故により停止した場合</p>
      <p>
        （3）天災、地変、火災、ストライキ、通商停止、戦争、内乱、感染症の流行その他の不可抗力により本サービスの運営ができなくなった場合
      </p>
      <p>
        （4）その他、甲が本サービスの停止または中断が必要と合理的に判断した場合
      </p>
      <p>
        3.甲は、本サービスの全部または一部を終了することができます。この場合において、甲はモニターに対して、できる限り事前に通知するよう努めます。
      </p>
      <p>
        4.本条に基づく本サービスの変更、追加、停止、中断または終了よりモニターに生じた不利益、損害について、甲は一切の責任を負いません。
      </p>
      <br />
      <p>第18条（通知）</p>
      <p>
        1.本サイトに関する甲からモニターへの連絡・通知は、本サイト上の掲示その他甲が適当と判断する方法によって行うものとします。甲は、モニターから甲が別途定める方式に従った連絡先の変更に係る届出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは発信時にモニターへ到達したものとみなします。
      </p>
      <p>
        2.モニターから甲への連絡は、本サイトに設置するお問い合わせフォーム又は甲が別途指定する方法によるものとします。
      </p>
      <br />
      <p>第19条（権利義務の譲渡禁止）</p>
      <p>
        1.モニターは、甲の書面による事前の承諾なく、本規約上の地位、本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
      </p>
      <p>
        2.甲は、本サービスにかかる事業を他社に承継させた場合には、当該承継に伴い本規約上の地位、本規約に基づく権利および義務ならびにモニターの登録情報その他の情報を当該事業譲渡の譲受人に承継させることができるものとし、モニターは、かかる承継につき本項において予め同意したものとします。なお、本項に定める事業の承継には、事業譲渡のみならず、会社分割その他事業が承継されるあらゆる場合を含むものとします。
      </p>
      <br />
      <p>第20条（分離可能性）</p>
      <p>
        1.本規約のいずれかの条項が、法令等により無効と判断された場合であっても、それ以外の条項については、継続して有効に存続するものとします。
      </p>
      <p>
        2.本規約の規定の一部があるモニターとの関係で無効または取消となった場合でも、本規約は他のモニターとの関係では有効とします。
      </p>
      <br />
      <p>第21条（準拠法・裁判管轄）</p>
      <p>1.本規約の準拠法は日本法とします。</p>
      <p>
        2.本サイト又は本規約に関連して、モニターと甲との間において紛争が生じた場合、当該紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </p>
      <br />
      <p>2022年　3月　29日施行</p>
    </StyledTermsOfServiceContent>
  );
};

export {TermsOfServiceContent};
