import styled from '@emotion/styled';

const StyledAMCInputHeader = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
`;
const AMCInputHeader = ({children}) => {
  return <StyledAMCInputHeader>{children}</StyledAMCInputHeader>;
};

export {AMCInputHeader};
