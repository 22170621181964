import styled from '@emotion/styled';

const StyledHelpLink = styled.a`
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

const HelpLink = ({children, href}) => {
  return (
    <StyledHelpLink target="_blank" rel="noreferrer" href={href}>
      {children}
    </StyledHelpLink>
  );
};

export {HelpLink};
