import React from 'react';
import {Route} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';

// component: Component >>> 仮引数の別名定義
const PrivateRoute = ({component: Component, ...rest}) => {
  const {currentUser} = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
          return;
        } else {
          window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
          return;
        }
      }}
    ></Route>
  );
};

export {PrivateRoute};
