const getSupaColoRedirectURL = () => {
  if (process.env.REACT_APP_COOL_ENV === 'production') {
    return process.env.REACT_APP_SUPCOLO_PRD_URL;
  }
  return process.env.REACT_APP_SUPCOLO_DEV_URL;
};

const getCheckAMCNumberEndpointURL = () => {
  if (process.env.REACT_APP_COOL_ENV === 'production') {
    return process.env.REACT_APP_AMC_AUTH_PRD_URL;
  }
  return process.env.REACT_APP_AMC_AUTH_DEV_URL;
};

export {getSupaColoRedirectURL, getCheckAMCNumberEndpointURL};
