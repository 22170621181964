import {useRef, useState} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/css';
import {Link, useHistory} from 'react-router-dom';

import {Form, Button} from 'react-bootstrap';

import {Layout} from '../../layouts/default';

import {Notify} from '../../components/notify';
import {Spacer} from '../../components/spacer';
import {useAuth} from '../../contexts/AuthContext';
import {HelpLinkContainer} from '../../components/link/HelpLinkContainer';
import {HelpLink} from '../../components/link/HelpLink';
import {SignInHeader} from '../../components/sign-in/SignInHeader';

import {LoadingIcon} from '../../icons/LoadingIcon';
import {PasswordIcon} from '../../icons/PasswordIcon';

import {isValidEmail} from '../../plugins/email';
import {isSafePasswordPattern} from '../../plugins/password';
import {getRandomValue} from '../../plugins/random';
import {getSupaColoRedirectURL} from '../../plugins/url';
import {sanitize} from 'dompurify';
import {MD5} from 'crypto-js';

import {database} from '../../firebase';

const StyledSignIn = styled.div`
  width: 100%;
`;

const SignIn = ({children}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;

  const emailRef = useRef();
  const passwordRef = useRef();
  const {login} = useAuth();
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [displayPasswordType, setDisplayPasswordType] = useState('password');

  const history = useHistory();

  const isRegisteredAMCNumber = async ({user}) => {
    const {db, doc, getDoc} = {...database};
    const docRef = doc(db, 'users_with_amc', user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return {amcNumber: data.amc, isRegistered: true};
    } else {
      return {amcNumber: null, isRegistered: false};
    }
  };

  const isAllGreenCheck = () => {
    let _isAllGreenCheck = false;
    if (passwordRef.current.value) {
      _isAllGreenCheck = true;
    }

    _isAllGreenCheck =
      _isAllGreenCheck &&
      isSafePasswordPattern({password: passwordRef.current.value});

    _isAllGreenCheck =
      _isAllGreenCheck && isValidEmail({email: emailRef.current.value});

    return _isAllGreenCheck;
  };

  const handleChangeEmail = (e) => {
    const value = emailRef.current.value;
    if (!isValidEmail({email: value})) {
      setErrorMessage('有効なメールアドレス形式ではありません xxx@yyy.zzz');
      setDisabled(true);
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  };

  const handleChangePassword = (e) => {
    const passwordValue = passwordRef.current.value;
    setPassword(passwordValue);
    if (!isSafePasswordPattern({password: passwordValue})) {
      setErrorMessage(
        'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
      );
      setDisabled(true);
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  };

  const handleBlur = (e) => {
    if (isAllGreenCheck()) {
      setDisabled(false);
      setErrorMessage('');
    } else {
      setDisabled(true);
    }
  };

  const toggleShowPassword = (e) => {
    setIsShowPassword((isShowPassword) => {
      return !isShowPassword;
    });
    if (isShowPassword) {
      setDisplayPasswordType('password');
    } else {
      setDisplayPasswordType('text');
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setErrorMessage('');
      setLoading(true);

      const resultLoginInfo = await login({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });

      if (resultLoginInfo.user && resultLoginInfo.user.emailVerified) {
        const {amcNumber, isRegistered} = {
          ...(await isRegisteredAMCNumber({user: resultLoginInfo.user})),
        };

        if (isRegistered) {
          setLoading(false);
          setSuccessMessage(
            '既にAMC番号が登録済みですね。スパコロサイトへ自動遷移します'
          );
          const redirectURL = `${getSupaColoRedirectURL()}?media_user_id=${getRandomValue()}${Number(
            sanitize(amcNumber)
          )}${getRandomValue()}&email=${getRandomValue()}${MD5(
            resultLoginInfo.user.email
          )}${getRandomValue()}`;
          setTimeout(() => {
            window.location.href = redirectURL;
          }, 5000);
        } else {
          setLoading(false);
          setSuccessMessage('ログインしました');
          setTimeout(() => {
            history.push('/amc-input');
          }, 1200);
        }
      } else {
        setLoading(false);
        setSuccessMessage(
          '本登録確認に関するメールをお送りしておりますが、本登録が完了しておりません。パスワード再設定に関するメールをお送りしますので再設定後、本登録の完了手続きをお願いいたします。'
        );
        setTimeout(() => {
          history.push('/forgot-password', {
            willResendedEmail: emailRef.current.value,
          });
        }, 5000);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('メールアドレスまたはパスワードが登録情報と一致しません');
      setLoading(false);
      setDisabled(true);
    }
  }

  return (
    <Layout>
      <StyledSignIn>
        <Spacer />
        <SignInHeader>{'ログイン'}</SignInHeader>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              required
              type="email"
              ref={emailRef}
              placeholder="メールアドレスを入力"
              onChange={(e) => {
                handleChangeEmail(e);
              }}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Spacer />
          <Form.Group
            style={{
              position: 'relative',
              userSelect: 'none',
            }}
          >
            <Form.Control
              required
              autoComplete="current-password"
              type={displayPasswordType}
              pattern="^[\da-zA-Z]{8,16}$"
              minLength="8"
              maxLength="16"
              ref={passwordRef}
              value={password}
              placeholder="パスワードを入力"
              onChange={(e) => {
                handleChangePassword(e);
              }}
              onBlur={handleBlur}
            />
            <PasswordIcon
              isShowPassword={isShowPassword}
              handleClick={toggleShowPassword}
            />
          </Form.Group>
          <Spacer />
          <Notify
            errorMessage={errorMessage}
            successMessage={successMessage}
            style={{
              height: `130px`,
            }}
          />
          <Spacer />
          <Button
            disabled={disabled}
            className="w-100"
            type="submit"
            style={{
              position: 'relative',
            }}
          >
            {'ログイン'}
            <LoadingIcon isLoading={loading} />
          </Button>
        </Form>
        <Spacer />
        <HelpLinkContainer>
          <Link
            to="/forgot-password"
            className={css`
              text-decoration: none;
            `}
          >
            {'パスワードをお忘れの方はこちら'}
          </Link>
        </HelpLinkContainer>
        <HelpLinkContainer>
          <HelpLink
            href="https://enq.supcolo.jp/enquete/spclbdd3095299"
            target="_blank"
            rel="noreferrer"
          >
            {'ログインでお困りの方はこちら'}
          </HelpLink>
        </HelpLinkContainer>
      </StyledSignIn>
    </Layout>
  );
};

export {SignIn};
