import styled from '@emotion/styled';

const StyledPleaseConfirmReceiveMailboxPageContent = styled.div`
  flex: 1 1 auto;
  padding: 1rem 1rem;
`;

const PleaseConfirmReceiveMailboxPageContent = ({children}) => {
  return (
    <StyledPleaseConfirmReceiveMailboxPageContent>
      {children}
    </StyledPleaseConfirmReceiveMailboxPageContent>
  );
};

export {PleaseConfirmReceiveMailboxPageContent};
