// https://www.darrenlester.com/blog/recognising-japanese-characters-with-javascript
function some(str, callback) {
  return Array.prototype.some.call(str, callback);
}

function isKanji(ch) {
  return (
    (ch >= '\u4e00' && ch <= '\u9faf') || (ch >= '\u3400' && ch <= '\u4dbf')
  );
}

function isHira(ch) {
  return ch >= '\u3040' && ch <= '\u309f';
}

function isKatakana(ch) {
  return ch >= '\u30a0' && ch <= '\u30ff';
}
function hasKanji(str) {
  return some(str, isKanji);
}

function hasHira(str) {
  return some(str, isHira);
}

function hasKatakana(str) {
  return some(str, isKatakana);
}

const isValidEmail = ({email}) => {
  const regexEmail = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const isMatch = regexEmail.test(email);
  if (!isMatch) {
    return false;
  }

  if (hasKanji(email)) {
    return false;
  }

  if (hasHira(email)) {
    return false;
  }

  if (hasKatakana(email)) {
    return false;
  }

  return true;
};

export {isValidEmail};
