import styled from '@emotion/styled';

const StyledChangePasswordHeader = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
const ChangePasswordHeader = ({children}) => {
  return <StyledChangePasswordHeader>{children}</StyledChangePasswordHeader>;
};

export {ChangePasswordHeader};
