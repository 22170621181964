import styled from '@emotion/styled';
const StyledPleaseConfirmReceiveMailboxPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;

const PleaseConfirmReceiveMailboxPage = ({children}) => {
  return (
    <StyledPleaseConfirmReceiveMailboxPage>
      {children}
    </StyledPleaseConfirmReceiveMailboxPage>
  );
};

export {PleaseConfirmReceiveMailboxPage};
