import styled from '@emotion/styled';

const StyledCompletePasswordNotifyMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompletePasswordNotifyMessage = ({children}) => {
  return (
    <StyledCompletePasswordNotifyMessage>
      {children}
    </StyledCompletePasswordNotifyMessage>
  );
};

export {CompletePasswordNotifyMessage};
