import styled from '@emotion/styled';
const StyledPleaseConfirmReceiveMailboxPageDescription = styled.p`
  @media screen and (max-device-width: 768px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PleaseConfirmReceiveMailboxPageDescription = ({children}) => {
  return (
    <StyledPleaseConfirmReceiveMailboxPageDescription>
      {children}
    </StyledPleaseConfirmReceiveMailboxPageDescription>
  );
};

export {PleaseConfirmReceiveMailboxPageDescription};
