import styled from '@emotion/styled';
const StyledPleaseConfirmReceiveMailboxPageMiniDescription = styled.p`
  font-size: 0.8rem;
  @media screen and (max-device-width: 768px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PleaseConfirmReceiveMailboxPageMiniDescription = ({children}) => {
  return (
    <StyledPleaseConfirmReceiveMailboxPageMiniDescription>
      {children}
    </StyledPleaseConfirmReceiveMailboxPageMiniDescription>
  );
};

export {PleaseConfirmReceiveMailboxPageMiniDescription};
