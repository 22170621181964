import styled from '@emotion/styled';

const StyledPasswordRestrictionInfoMessage = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  padding-top: 3px;
`;

const PasswordRestrictionInfoMessage = ({children, className, style}) => {
  return (
    <StyledPasswordRestrictionInfoMessage
      className={className}
      style={{...style}}
    >
      {children}
    </StyledPasswordRestrictionInfoMessage>
  );
};

export {PasswordRestrictionInfoMessage};
