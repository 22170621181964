import styled from '@emotion/styled';
const StyledPleaseConfirmReceiveMailboxPageSubHeader = styled.h6`
  @media screen and (max-device-width: 768px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PleaseConfirmReceiveMailboxPageSubHeader = ({children}) => {
  return (
    <StyledPleaseConfirmReceiveMailboxPageSubHeader>
      {children}
    </StyledPleaseConfirmReceiveMailboxPageSubHeader>
  );
};

export {PleaseConfirmReceiveMailboxPageSubHeader};
