import {useState, useEffect} from 'react';
import {queryParamParser} from '../../plugins/queryParamParser';
import {ChangePassword} from '../../pages/change-password';
import {SignIn} from '../../pages/sign-in';
import {useAuth} from '../../contexts/AuthContext';
// https://firebase.google.com/docs/auth/custom-email-handler?hl=ja
const AuthTypeHandler = ({children}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;
  const {confirmEmail} = useAuth();

  const [actionState, setActionState] = useState({
    mode: '',
    actionCode: '',
    continueURL: '',
  });

  useEffect(() => {
    const mode = queryParamParser({name: 'mode'});
    const actionCode = queryParamParser({name: 'oobCode'});
    const continueURL = queryParamParser({name: 'continueUrl'});
    setActionState({
      mode,
      actionCode,
      continueURL,
    });
  }, []);

  const renderPageBasedOnAuthType = ({authType}) => {
    // https://github.com/ojisan-toybox/firebase-ipass-login/blob/master/src/pages/action.tsx#L79
    switch (authType) {
      case 'resetPassword':
        return <ChangePassword actionState={actionState} />;
      case 'signIn':
        return <SignIn />;
      case 'verifyEmail':
        (async () => {
          try {
            await confirmEmail({actionCode: actionState.actionCode});
          } catch (error) {}
        })();
        return <SignIn />;
      default:
        return <></>;
    }
  };

  return <>{renderPageBasedOnAuthType({authType: actionState.mode})}</>;
};

export {AuthTypeHandler};
