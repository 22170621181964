import styled from '@emotion/styled';
import {Button} from 'react-bootstrap';
import {Layout} from '../../layouts/default';
import {Spacer} from '../../components/spacer';
import {CompletePasswordNotifyMessage} from '../../components/complete-password/CompletePasswordNotifyMessage';

const StyledCompletePassword = styled.div`
  width: 100%;
`;

const CompletePassword = ({newPassword}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;
  if (!newPassword) {
    window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
    return;
  }

  const handleClick = (e) => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
      return;
    }, 1200);
  };

  return (
    <Layout>
      <StyledCompletePassword>
        <Spacer />
        <CompletePasswordNotifyMessage>
          {'新しいパスワードの登録を完了しました'}
        </CompletePasswordNotifyMessage>
        <Spacer />
        <Button className="w-100" onClick={handleClick}>
          {'ANAリサーチログインページへ'}
        </Button>
      </StyledCompletePassword>
    </Layout>
  );
};

export {CompletePassword};
