import styled from '@emotion/styled';

const StyledChangePasswordPromotionMessage = styled.p``;
const ChangePasswordPromotionMessage = ({children}) => {
  return (
    <StyledChangePasswordPromotionMessage>
      {children}
    </StyledChangePasswordPromotionMessage>
  );
};

export {ChangePasswordPromotionMessage};
