import {useRef, useState} from 'react';
import styled from '@emotion/styled';
import {Form, Button} from 'react-bootstrap';
import {useAuth} from '../../contexts/AuthContext';
import {useHistory} from 'react-router-dom';

import {Layout} from '../../layouts/default';
import {Notify} from '../../components/notify';
import {Spacer} from '../../components/spacer';
import {LoadingIcon} from '../../icons/LoadingIcon';
import {PasswordIcon} from '../../icons/PasswordIcon';
import {PasswordRestrictionInfoMessage} from '../../components/password/PasswordRestrictionInfoMessage';
import {ChangePasswordHeader} from '../../components/change-password/ChangePasswordHeader';
import {ChangePasswordPromotionMessage} from '../../components/change-password/ChangePasswordPromotionMessage';

import {isSafePasswordPattern} from '../../plugins/password';

const StyledChangePassword = styled.div`
  width: 100%;
`;

const ChangePassword = ({actionState}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const [displayPasswordType, setDisplayPasswordType] = useState('password');
  const [displayConfirmPasswordType, setDisplayConfirmPasswordType] =
    useState('password');

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const {changePassword} = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  if (Object.keys(actionState).length === 0) {
    window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
    return;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setErrorMessage('パスワードとパスワード（確認用）が異なっています');
      setDisabled(true);
      return;
    }

    try {
      setErrorMessage('');
      setLoading(true);
      await changePassword({
        mode: actionState.mode,
        actionCode: actionState.actionCode,
        continueURL: actionState.continueURL,
        password: passwordRef.current.value,
      });
      setLoading(false);
      setSuccessMessage('パスワードが変更されました');
      setTimeout(() => {
        history.push('/complete-password', {
          newPassword: passwordRef.current.value,
        });
      }, 1200);
    } catch (error) {
      console.log(error);
      setErrorMessage('パスワードの登録ができませんでした');
      setDisabled(true);
      return;
    }
  }

  const handleChangePassword = (e) => {
    const passwordValue = passwordRef.current.value;
    const passwordConfirmValue = passwordConfirmRef.current.value;
    setPassword(passwordValue);
    if (passwordValue !== passwordConfirmValue) {
      setErrorMessage('パスワードとパスワード（確認用）が異なっています');
      setDisabled(true);
      return;
    }
    if (!isSafePasswordPattern({password: passwordValue})) {
      setErrorMessage(
        'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
      );
      setDisabled(true);
      return;
    }

    setErrorMessage('');
  };

  const handleChangeConfirmPassword = (e) => {
    const passwordValue = passwordRef.current.value;
    const passwordConfirmValue = passwordConfirmRef.current.value;
    setConfirmPassword(passwordConfirmValue);
    if (passwordValue !== passwordConfirmValue) {
      setErrorMessage('パスワードとパスワード（確認用）が異なっています');
      setDisabled(true);
      return;
    }
    if (!isSafePasswordPattern({password: passwordConfirmValue})) {
      setErrorMessage(
        'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
      );
      setDisabled(true);
      return;
    }
    setErrorMessage('');
  };

  const isAllGreenCheck = () => {
    let _isAllGreenCheck = false;
    if (
      passwordRef.current.value &&
      passwordConfirmRef.current.value &&
      passwordRef.current.value === passwordConfirmRef.current.value
    ) {
      _isAllGreenCheck = true;
    }

    _isAllGreenCheck =
      _isAllGreenCheck &&
      isSafePasswordPattern({password: passwordRef.current.value});

    _isAllGreenCheck =
      _isAllGreenCheck &&
      isSafePasswordPattern({password: passwordConfirmRef.current.value});

    return _isAllGreenCheck;
  };

  const handleBlur = (e) => {
    if (isAllGreenCheck()) {
      setDisabled(false);
      setErrorMessage('');
    } else {
      setDisabled(true);
    }
  };

  const toggleShowPassword = (e) => {
    setIsShowPassword((isShowPassword) => {
      return !isShowPassword;
    });
    if (isShowPassword) {
      setDisplayPasswordType('password');
    } else {
      setDisplayPasswordType('text');
    }
  };

  const toggleShowConfirmPassword = (e) => {
    setIsShowConfirmPassword((isShowConfirmPassword) => {
      return !isShowConfirmPassword;
    });
    if (isShowConfirmPassword) {
      setDisplayConfirmPasswordType('password');
    } else {
      setDisplayConfirmPasswordType('text');
    }
  };

  return (
    <Layout>
      <StyledChangePassword>
        <Spacer />
        <ChangePasswordHeader>{'新しくパスワードを登録'}</ChangePasswordHeader>
        <ChangePasswordPromotionMessage>
          {'新しく設定するパスワードを入力してください'}
        </ChangePasswordPromotionMessage>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            style={{
              position: 'relative',
              userSelect: 'none',
            }}
          >
            <Form.Control
              required
              autoComplete="new-password"
              type={displayPasswordType}
              pattern="^[\da-zA-Z]{8,16}$"
              minLength="8"
              maxLength="16"
              ref={passwordRef}
              value={password}
              placeholder="パスワードを入力"
              onChange={(e) => {
                handleChangePassword(e);
              }}
              onBlur={handleBlur}
            />

            <PasswordIcon
              isShowPassword={isShowPassword}
              handleClick={toggleShowPassword}
            />
          </Form.Group>
          <Spacer />
          <Form.Group
            style={{
              position: 'relative',
              userSelect: 'none',
            }}
          >
            <Form.Control
              required
              type={displayConfirmPasswordType}
              pattern="^[\da-zA-Z]{8,16}$"
              minLength="8"
              maxLength="16"
              ref={passwordConfirmRef}
              value={confirmPassword}
              placeholder="パスワード（確認用）"
              onChange={(e) => {
                handleChangeConfirmPassword(e);
              }}
              onBlur={handleBlur}
            />
            <PasswordIcon
              isShowPassword={isShowConfirmPassword}
              handleClick={toggleShowConfirmPassword}
            />
          </Form.Group>
          <PasswordRestrictionInfoMessage>
            {
              'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
            }
          </PasswordRestrictionInfoMessage>
          <Spacer />
          <Notify errorMessage={errorMessage} successMessage={successMessage} />
          <Spacer />
          <Button
            className="w-100"
            type="submit"
            disabled={disabled}
            style={{
              position: 'relative',
            }}
          >
            {'パスワードを登録'}
            <LoadingIcon isLoading={loading} />
          </Button>
        </Form>
        <Spacer />
      </StyledChangePassword>
    </Layout>
  );
};

export {ChangePassword};
