import styled from '@emotion/styled';

const StyledSignUpPromotionMessage = styled.p``;

const SignUpPromotionMessage = ({children, className, style}) => {
  return (
    <StyledSignUpPromotionMessage className={className} style={{...style}}>
      {children}
    </StyledSignUpPromotionMessage>
  );
};

export {SignUpPromotionMessage};
