import {Layout} from '../../layouts/please-confirm-receive-mailbox';
import {Spacer} from '../../components/spacer';
import {HelpLinkContainer} from '../../components/link/HelpLinkContainer';
import {HelpLink} from '../../components/link/HelpLink';
import {PleaseConfirmReceiveMailboxPage} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPage';
import {PleaseConfirmReceiveMailboxPageHeader} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPageHeader';
import {PleaseConfirmReceiveMailboxPageSubHeader} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPageSubHeader';
import {PleaseConfirmReceiveMailboxPageContent} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPageContent';
import {PleaseConfirmReceiveMailboxPageDescription} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPageDescription';
import {PleaseConfirmReceiveMailboxPageMiniDescription} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPageMiniDescription';
import {PleaseConfirmReceiveMailboxPageParagraph} from '../../components/please-confirm-receive-mailbox/PleaseConfirmReceiveMailboxPageParagraph';
import {PreviewReceiveEmail} from '../../components/preview-receive-email';

const PleaseConfirmReceiveMailbox = ({pageType, email}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;
  const renderHeader = () => {
    switch (pageType) {
      case 'forgot-password':
        return `パスワードの再登録手続きに関するご案内メールをお送りしましたので、ご確認ください。`;
      case 'sign-up':
        return `メールアドレスとパスワードの登録を完了しました`;
      default:
        break;
    }
  };

  const renderSubHeader = () => {
    switch (pageType) {
      case 'forgot-password':
        return null;
      case 'sign-up':
        return `「ANAリサーチのメールアドレスの確認」のタイトルでメールが届きます。メール記載のURLよりお進みください。`;
      default:
        break;
    }
  };

  const renderParagraph = () => {
    switch (pageType) {
      case 'forgot-password':
        return null;
      case 'sign-up':
        return `配信メール画面イメージ`;
      default:
        break;
    }
  };

  const renderReceiveEmailPreview = () => {
    switch (pageType) {
      case 'forgot-password':
        return null;
      case 'sign-up':
        return (
          <PreviewReceiveEmail
            src={'/assets/image/receive-mail-preview.png'}
            alt={'receive-mail-preview'}
          />
        );
      default:
        break;
    }
  };

  if (!pageType) {
    window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
    return;
  }

  const renderDiscription = () => {
    switch (pageType) {
      case 'forgot-password':
        return null;
      case 'sign-up':
        return (
          <PleaseConfirmReceiveMailboxPageDescription>
            {
              'ご入力いただいたメールアドレスに、本登録に関するご案内メールをお送りしましたので本登録をお願いいたします。'
            }
          </PleaseConfirmReceiveMailboxPageDescription>
        );
      default:
        break;
    }
  };

  return (
    <Layout>
      <PleaseConfirmReceiveMailboxPage>
        <PleaseConfirmReceiveMailboxPageContent>
          <PleaseConfirmReceiveMailboxPageHeader>
            {renderHeader()}
          </PleaseConfirmReceiveMailboxPageHeader>
          <PleaseConfirmReceiveMailboxPageSubHeader>
            {renderSubHeader()}
          </PleaseConfirmReceiveMailboxPageSubHeader>
          <PleaseConfirmReceiveMailboxPageParagraph>
            {renderParagraph()}
          </PleaseConfirmReceiveMailboxPageParagraph>
          {renderReceiveEmailPreview()}
          <Spacer />
          {renderDiscription()}
          <Spacer />
          <PleaseConfirmReceiveMailboxPageMiniDescription>
            {
              '10分以上経過しても仮登録メールが届かない場合は迷惑メールフォルダに入っていないかをご確認ください。'
            }
          </PleaseConfirmReceiveMailboxPageMiniDescription>
          <HelpLinkContainer>
            <HelpLink
              href="https://enq.supcolo.jp/enquete/spclbdd3095299"
              target="_blank"
              rel="noreferrer"
            >
              {'メールが届かない場合はこちら'}
            </HelpLink>
          </HelpLinkContainer>
        </PleaseConfirmReceiveMailboxPageContent>
      </PleaseConfirmReceiveMailboxPage>
    </Layout>
  );
};

export {PleaseConfirmReceiveMailbox};
