import styled from '@emotion/styled';

const StyledHelpLinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const HelpLinkContainer = ({children}) => {
  return <StyledHelpLinkContainer>{children}</StyledHelpLinkContainer>;
};

export {HelpLinkContainer};
