import styled from '@emotion/styled';

const StyledTermsOfServiceHeader = styled.h6`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
`;
const TermsOfServiceHeader = ({children}) => {
  return <StyledTermsOfServiceHeader>{children}</StyledTermsOfServiceHeader>;
};

export {TermsOfServiceHeader};
