import {useRef, useState} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/css';
import {useHistory} from 'react-router-dom';

import {Form, Button} from 'react-bootstrap';

import {useAuth} from '../../contexts/AuthContext';
import {Layout} from '../../layouts/default';

import {Notify} from '../../components/notify';
import {Spacer} from '../../components/spacer';
import {SignUpHeader} from '../../components/sign-up/SignUpHeader';
import {SignUpPromotionMessage} from '../../components/sign-up/SignUpPromotionMessage';
import {PasswordRestrictionInfoMessage} from '../../components/password/PasswordRestrictionInfoMessage';

import {CaptureResize} from '../../components/resize';
import {HelpLinkContainer} from '../../components/link/HelpLinkContainer';
import {HelpLink} from '../../components/link/HelpLink';
import {FormButtonContainer} from '../../components/sign-up/FormButtonContainer';
import {TermsOfServiceContainer} from '../../components/sign-up/TermsOfServiceContainer';
import {TermsOfServiceHeader} from '../../components/sign-up/TermsOfServiceHeader';
import {TermsOfServiceContent} from '../../components/sign-up/TermsOfServiceContent';
import {LoadingIcon} from '../../icons/LoadingIcon';
import {PasswordIcon} from '../../icons/PasswordIcon';

import {isValidEmail} from '../../plugins/email';
import {isSafePasswordPattern} from '../../plugins/password';

const StyledSignUp = styled.div`
  width: 100%;
`;

const SignUp = ({children}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;

  const c = useRef(null);

  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const {signUp, verifyEmail} = useAuth();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const [displayPasswordType, setDisplayPasswordType] = useState('password');
  const [displayConfirmPasswordType, setDisplayConfirmPasswordType] =
    useState('password');

  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const termOfServiceCheckedRef = useRef();
  const [isChecked, setIsChecked] = useState(false);

  const isAllGreenCheck = () => {
    let _isAllGreenCheck = false;
    if (
      passwordRef.current.value &&
      passwordConfirmRef.current.value &&
      passwordRef.current.value === passwordConfirmRef.current.value
    ) {
      _isAllGreenCheck = true;
    }

    _isAllGreenCheck =
      _isAllGreenCheck && isValidEmail({email: emailRef.current.value});

    _isAllGreenCheck =
      _isAllGreenCheck &&
      isSafePasswordPattern({password: passwordRef.current.value});

    _isAllGreenCheck =
      _isAllGreenCheck &&
      isSafePasswordPattern({password: passwordConfirmRef.current.value});

    _isAllGreenCheck =
      _isAllGreenCheck && termOfServiceCheckedRef.current.checked;

    return _isAllGreenCheck;
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    if (isAllGreenCheck()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setErrorMessage('パスワードとパスワード（確認用）が異なっています');
      return;
    }

    try {
      setErrorMessage('');
      setDisabled(true);
      setLoading(true);
      const result = await signUp({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });
      console.log(result, emailRef.current.value);
      await verifyEmail({user: result.user});

      setLoading(false);
      setSuccessMessage('サインアップしました');
      setTimeout(() => {
        history.push('/please-confirm-receive-mailbox', {
          pageType: 'sign-up',
        });
      }, 1200);
    } catch (error) {
      console.log(error);
      setDisabled(false);
      setLoading(false);
      setErrorMessage(
        '既に同一のメールアドレスで登録されている可能性があります。'
      );
    }
  }

  const handleChangeEmail = (e) => {
    const value = emailRef.current.value;
    if (!isValidEmail({email: value})) {
      setErrorMessage('有効なメールアドレス形式ではありません xxx@yyy.zzz');
      setDisabled(true);
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  };

  const handleChangePassword = (e) => {
    const passwordValue = passwordRef.current.value;
    const passwordConfirmValue = passwordConfirmRef.current.value;
    setPassword(passwordValue);
    if (passwordValue !== passwordConfirmValue) {
      setErrorMessage('パスワードとパスワード（確認用）が異なっています');
      setDisabled(true);
      return;
    }
    if (!isSafePasswordPattern({password: passwordValue})) {
      setErrorMessage(
        'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
      );
      setDisabled(true);
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  };

  const handleChangeConfirmPassword = (e) => {
    const passwordValue = passwordRef.current.value;
    const passwordConfirmValue = passwordConfirmRef.current.value;
    setConfirmPassword(passwordConfirmValue);
    if (passwordValue !== passwordConfirmValue) {
      setErrorMessage('パスワードとパスワード（確認用）が異なっています');
      setDisabled(true);
      return;
    }
    if (!isSafePasswordPattern({password: passwordConfirmValue})) {
      setErrorMessage(
        'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
      );
      setDisabled(true);
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  };

  const handleBlur = (e) => {
    if (isAllGreenCheck()) {
      setDisabled(false);
      setErrorMessage('');
    } else {
      setDisabled(true);
    }
  };

  const toggleShowPassword = (e) => {
    setIsShowPassword((isShowPassword) => {
      return !isShowPassword;
    });
    if (isShowPassword) {
      setDisplayPasswordType('password');
    } else {
      setDisplayPasswordType('text');
    }
  };

  const toggleShowConfirmPassword = (e) => {
    setIsShowConfirmPassword((isShowConfirmPassword) => {
      return !isShowConfirmPassword;
    });
    if (isShowConfirmPassword) {
      setDisplayConfirmPasswordType('password');
    } else {
      setDisplayConfirmPasswordType('text');
    }
  };

  return (
    <Layout>
      <StyledSignUp>
        <Spacer />
        <SignUpHeader>{'新規会員登録'}</SignUpHeader>
        <SignUpPromotionMessage>
          {
            '「アンケートモニター利用規約」をご確認の上、メールアドレスとパスワードを登録してください。'
          }
        </SignUpPromotionMessage>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              required
              type="email"
              ref={emailRef}
              placeholder="メールアドレスを入力"
              onChange={(e) => {
                handleChangeEmail(e);
              }}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Spacer />
          <Form.Group
            style={{
              position: 'relative',
              userSelect: 'none',
            }}
          >
            <Form.Control
              required
              autoComplete="new-password"
              type={displayPasswordType}
              pattern="^[\da-zA-Z]{8,16}$"
              minLength="8"
              maxLength="16"
              ref={passwordRef}
              value={password}
              placeholder="パスワードを入力"
              onChange={(e) => {
                handleChangePassword(e);
              }}
              onBlur={handleBlur}
            />
            <PasswordIcon
              isShowPassword={isShowPassword}
              handleClick={toggleShowPassword}
            />
          </Form.Group>
          <Spacer />
          <Form.Group
            style={{
              position: 'relative',
              userSelect: 'none',
            }}
          >
            <Form.Control
              required
              type={displayConfirmPasswordType}
              ref={passwordConfirmRef}
              pattern="^[\da-zA-Z]{8,16}$"
              minLength="8"
              maxLength="16"
              value={confirmPassword}
              placeholder="パスワード（確認用）"
              onChange={(e) => {
                handleChangeConfirmPassword(e);
              }}
              onBlur={handleBlur}
            />
            <PasswordIcon
              isShowPassword={isShowConfirmPassword}
              handleClick={toggleShowConfirmPassword}
            />
          </Form.Group>
          <PasswordRestrictionInfoMessage>
            {
              'パスワードは半角英字、数字の組み合わせで8文字以上16文字以内で入力してください。半角英字の入力は大文字小文字どちらも使用可能です。'
            }
          </PasswordRestrictionInfoMessage>
          <Spacer />
          <CaptureResize captureRef={c}>
            {(size) => {
              return (
                <>
                  <TermsOfServiceHeader className={css``}>
                    {'ANAリサーチ アンケートモニター利用規約'}
                  </TermsOfServiceHeader>
                  <TermsOfServiceContainer ref={c}>
                    <TermsOfServiceContent></TermsOfServiceContent>
                  </TermsOfServiceContainer>
                </>
              );
            }}
          </CaptureResize>

          <Spacer />

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              ref={termOfServiceCheckedRef}
              type="checkbox"
              onChange={(e) => {
                toggleCheckbox(e);
              }}
              label="利用規約に同意する"
              style={{
                marginRight: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className={css`
                & input {
                  margin-right: 5px;
                  margin-bottom: 4px;
                }
              `}
            />
          </Form.Group>
          <Spacer />
          <Notify
            errorMessage={errorMessage}
            successMessage={successMessage}
            style={{
              height: `100px`,
            }}
          />
          <Spacer />
          <FormButtonContainer>
            <Button
              disabled={disabled}
              className="w-100"
              type="submit"
              style={{
                position: 'relative',
              }}
            >
              {'登録する'}
              <LoadingIcon isLoading={loading} />
            </Button>
          </FormButtonContainer>
        </Form>
        <HelpLinkContainer>
          <HelpLink
            href="https://enq.supcolo.jp/enquete/spclbdd3095299"
            target="_blank"
            rel="noreferrer"
          >
            {'ご登録できない場合はこちら'}
          </HelpLink>
        </HelpLinkContainer>
      </StyledSignUp>
    </Layout>
  );
};

export {SignUp};
