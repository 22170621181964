import styled from '@emotion/styled';
import {BsFillEyeFill} from 'react-icons/bs';
import {BsFillEyeSlashFill} from 'react-icons/bs';

const StyledPasswordIcon = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const PasswordIcon = ({handleClick, isShowPassword}) => {
  return (
    <StyledPasswordIcon onClick={handleClick}>
      {isShowPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
    </StyledPasswordIcon>
  );
};

export {PasswordIcon};
