import styled from '@emotion/styled';

const StyledPreviewReceiveEmailThumbnail = styled.div`
  @media screen and (max-device-width: 768px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledPreviewReceiveEmailThumbnailImage = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  @media screen and (max-device-width: 768px) and (orientation: landscape) {
    max-width: 50%;
  }
`;

const PreviewReceiveEmail = ({src, alt}) => {
  return (
    <StyledPreviewReceiveEmailThumbnail>
      <StyledPreviewReceiveEmailThumbnailImage src={src} alt={alt} />
    </StyledPreviewReceiveEmailThumbnail>
  );
};

export {PreviewReceiveEmail};
