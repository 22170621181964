import styled from '@emotion/styled';
const StyledPleaseConfirmReceiveMailboxPageHeader = styled.h5`
  @media screen and (max-device-width: 768px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PleaseConfirmReceiveMailboxPageHeader = ({children}) => {
  return (
    <StyledPleaseConfirmReceiveMailboxPageHeader>
      {children}
    </StyledPleaseConfirmReceiveMailboxPageHeader>
  );
};

export {PleaseConfirmReceiveMailboxPageHeader};
