import styled from '@emotion/styled';

const StyledForgotPasswordPromotionMessage = styled.p``;
const ForgotPasswordPromotionMessage = ({children}) => {
  return (
    <StyledForgotPasswordPromotionMessage>
      {children}
    </StyledForgotPasswordPromotionMessage>
  );
};

export {ForgotPasswordPromotionMessage};
