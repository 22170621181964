import {forwardRef} from 'react';
import styled from '@emotion/styled';

const StyledTermsOfServiceContainer = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
`;

const _TermsOfServiceContainer = ({children}, ref) => {
  return (
    <StyledTermsOfServiceContainer ref={ref}>
      {children}
    </StyledTermsOfServiceContainer>
  );
};

const TermsOfServiceContainer = forwardRef(_TermsOfServiceContainer);

export {TermsOfServiceContainer};
