import styled from '@emotion/styled';
const StyledSignInHeader = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const SignInHeader = ({children}) => {
  return <StyledSignInHeader>{children}</StyledSignInHeader>;
};

export {SignInHeader};
