import styled from '@emotion/styled';

const StyledFormButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2vw;
`;

const FormButtonContainer = ({children}) => {
  return <StyledFormButtonContainer>{children}</StyledFormButtonContainer>;
};

export {FormButtonContainer};
