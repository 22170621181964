import {useRef, useState} from 'react';
import styled from '@emotion/styled';
import {useHistory} from 'react-router-dom';

import {Form, Button} from 'react-bootstrap';

import {Layout} from '../../layouts/default';

import {Notify} from '../../components/notify';
import {Spacer} from '../../components/spacer';
import {useAuth} from '../../contexts/AuthContext';
import {HelpLinkContainer} from '../../components/link/HelpLinkContainer';
import {HelpLink} from '../../components/link/HelpLink';
import {ForgotPasswordHeader} from '../../components/forgot-password/ForgotPasswordHeader';
import {ForgotPasswordPromotionMessage} from '../../components/forgot-password/ForgotPasswordPromotionMessage';
import {LoadingIcon} from '../../icons/LoadingIcon';

import {isValidEmail} from '../../plugins/email';

const StyledForgotPassword = styled.div`
  width: 100%;
`;

const ForgotPassword = ({willResendedEmail}) => {
  window.location.href = `${process.env.REACT_APP_SUPCOLO_REDIRECT_URL}`;
  return;

  const emailRef = useRef();
  const [email, setEmail] = useState(
    willResendedEmail ? willResendedEmail : ''
  );
  const {resetPassword} = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setErrorMessage('');
      setLoading(true);
      await resetPassword({email: emailRef.current.value});
      setLoading(false);
      setSuccessMessage('送信しました');
      setTimeout(() => {
        history.push('/please-confirm-receive-mailbox', {
          pageType: 'forgot-password',
          email: emailRef.current.value,
        });
      }, 1200);
    } catch (error) {
      console.log(error);
      setErrorMessage('入力されたメールアドレスは登録されていません');
      setLoading(false);
      setDisabled(true);
    }
  }

  const handleChange = (e) => {
    const value = emailRef.current.value;
    setEmail(value);
    if (!isValidEmail({email: value})) {
      setErrorMessage('有効なメールアドレス形式ではありません xxx@yyy.zzz');
      setDisabled(true);
    } else {
      setDisabled(false);
      setErrorMessage('');
    }
  };

  const isAllGreenCheck = () => {
    const email = emailRef.current.value;
    let _isAllGreenCheck = false;
    if (email) {
      _isAllGreenCheck = true;
    }

    _isAllGreenCheck = _isAllGreenCheck && isValidEmail({email});

    return _isAllGreenCheck;
  };

  const handleBlur = (e) => {
    if (isAllGreenCheck()) {
      setDisabled(false);
      setErrorMessage('');
    } else {
      setDisabled(true);
    }
  };

  return (
    <Layout>
      <StyledForgotPassword>
        <Spacer />
        <ForgotPasswordHeader>
          {'パスワードの再登録手続き'}
        </ForgotPasswordHeader>
        <ForgotPasswordPromotionMessage>
          {
            'パスワード再設定に関するご案内を、ご登録頂いているメールアドレス宛にお送りします。'
          }
        </ForgotPasswordPromotionMessage>
        <ForgotPasswordPromotionMessage>
          {
            '※本登録に関するご案内メールの再送をご希望の場合も、こちらからパスワード再登録手続きの手順に沿ってお進みください。'
          }
        </ForgotPasswordPromotionMessage>
        <Spacer />
        <Form onSubmit={handleSubmit}>
          <Form.Group id="email">
            <Form.Control
              required
              type="email"
              ref={emailRef}
              value={email}
              placeholder="メールアドレスを入力"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Spacer />
          <Notify errorMessage={errorMessage} successMessage={successMessage} />
          <Spacer />
          <Button
            disabled={disabled}
            className="w-100"
            type="submit"
            style={{
              position: 'relative',
            }}
          >
            {'送信'}
            <LoadingIcon isLoading={loading} />
          </Button>
        </Form>
        <HelpLinkContainer>
          <HelpLink
            href="https://enq.supcolo.jp/enquete/spclbdd3095299"
            target="_blank"
            rel="noreferrer"
          >
            {'登録したメールアドレスをお忘れの方はこちら'}
          </HelpLink>
        </HelpLinkContainer>
      </StyledForgotPassword>
    </Layout>
  );
};

export {ForgotPassword};
